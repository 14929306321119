import api from "./api-service";
import { stringify } from "querystring";

const url = "vascular-phenomena";

export const find = (filter) => {
  return api().get(`${url}?${stringify(filter)}`);
};

export const create = (vascularPhenomenon) => {
  return api().post(url, vascularPhenomenon);
};

export const update = (vascularPhenomenon) => {
  return api().put(url, vascularPhenomenon);
};

export const findOne = (id) => {
  return api().get(`${url}/${id}`);
};
