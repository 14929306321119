<template>
  <div>
    <v-container>
      <perseu-card title="Fenômenos Vasculares">
        <template slot="title-right">
          <v-btn @click="create" color="secondary">
            Novo Fenômeno Vascular
          </v-btn>
        </template>
        <template slot="content">
          <v-switch v-model="query.active" label="Ativos" @change="find" />
          <v-data-table :items="items" :headers="headers" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <table-action-button
                text="Editar"
                @click="edit(item)"
                icon="edit"
              />
              <table-action-button
                v-if="item.active"
                text="Desativar"
                @click="activeOrDeactivate(item, false)"
                icon="delete"
              />
              <table-action-button
                v-else
                text="Ativar"
                @click="activeOrDeactivate(item, true)"
                icon="check"
              />
            </template>
          </v-data-table>
        </template>
      </perseu-card>
    </v-container>
    <form-vascular-phenomena
      ref="form"
      @created="createdForm"
      @updated="updatedForm"
    />
  </div>
</template>

<script>
import {
  find,
  findOne,
  update,
} from "@/services/vascular-phenomena-service.js";

export default {
  components: {
    "form-vascular-phenomena": () =>
      import("@/components/VascularPhenomena/Form"),
  },
  data: () => ({
    items: [],
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        width: 150,
        sortable: false,
        align: "start",
      },
    ],
    query: {
      active: true,
    },
  }),
  created() {
    this.find();
  },
  methods: {
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await find(this.query);
        this.items = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        await update(item);
        const index = this.findIndexItems(item);
        this.items.splice(index, 1);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async edit(item) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findOne(item.id);
        this.$refs.form.edit(data);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$refs.form.create();
    },
    createdForm(item) {
      this.items.push(item);
    },
    updatedForm(item) {
      const index = this.findIndexItems(item);
      this.items.splice(index, 1, item);
    },
    findIndexItems(itemSelected) {
      return this.items.findIndex((item) => item.id === itemSelected.id);
    },
  },
};
</script>

<style></style>
